#front-logo-wrapper {
  position: relative;
  width: 100%;
  height: calc(100vh - var(--header-h));
  display: flex;
  justify-content: center;
  align-items: center;
}
#front-logo-wrapper:first-child {
  position: relative;
  width: 100%;
}
#front-logo {
  width: 25%;
  min-width: 350px;
  opacity: 0.75;
  position: relative;
  top: -70px;
}

@media (max-width: 450px) {
  #front-wrapper {
    background-image: url("../../images/front_bg_small.jpg");
  }
  #front-logo {
    width: 60vw;
    min-width: 200px;
  }
}
