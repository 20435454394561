header {
  position: relative;
  width: 100%;
  height: var(--header-h);
  max-height: var(--header-h);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 2rem;
  transition: 0.5s;
  z-index: 999;
}

header:hover {
  background-color: rgba(105, 102, 102, 0.15);
}

header div.front-links {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
}

header div.front-contacts {
  min-width: fit-content;
  display: flex;
  gap: 2rem;
  flex-wrap: nowrap;
}

header div.front-contacts a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: clamp(14px, 0.85rem, 15px);
  transition: all 0.4s;
  letter-spacing: -0.5px;
  color: ghostwhite;
}
header div.front-contacts a:hover {
  scale: 0.99;
}

header div.front-contacts a > div {
  border: 2px solid var(--gold);
  border-radius: 50%;
  width: clamp(42px, 2.5rem, 55px);
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.1rem;
  position: relative;
}

header div.front-contacts a svg {
  font-size: 22px;
  color: ghostwhite;
  transition: all 0.4s;
}

header div.front-contacts a:hover svg {
  font-size: 20px;
  color: var(--gold);
}

header div.front-contacts a span {
  margin-left: 0.2rem;
  font-family: "Manrope", sans-serif;
  font-weight: 600;
}
header div.front-contacts a:hover span {
  transition: all 0.2s;
}
header div.front-contacts a:hover > div {
  transition: all 0.2s;
}
header .logo-wrapper {
  margin-right: 3rem;
}

header .logo-wrapper a svg {
  width: 14rem;
  max-width: 250px;
  cursor: pointer;
  fill: ghostwhite;
}

.nav-link {
  position: relative;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  font-size: clamp(14px, 0.85rem, 18px);
  cursor: pointer;
  color: ghostwhite;
  font-weight: 400;
  padding-bottom: 3px;
}

.nav-link:hover {
  transition: all 0.5s;
}

.nav-link::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(228, 127, 33);
  transform: scaleX(0);
  transition: transform 0.3s ease;
}

.nav-link:hover::before {
  transform: scaleX(1);
}
.nav-link.active::before {
  transform: scaleX(1);
}

.burger-menu {
  display: none;
}
.burger-list {
  display: none;
}

@keyframes navSlideIn {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
}

@keyframes navSlideOut {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
.onClose {
  animation: navSlideOut 0.5s ease-in-out;
}
.onOpen {
  animation: navSlideIn 0.5s ease-in-out;
}

@media (max-width: 920px) {
  header {
    justify-content: center;
    margin: 0;
  }

  header #header-logo {
    display: none;
  }

  header div.front-links {
    display: none;
  }

  .burger-menu {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    color: ghostwhite;
    z-index: 998;
  }

  .burger-menu .burger-btn {
    position: relative;
    z-index: 9999;
  }
  .burger-menu svg {
    position: relative;
    cursor: pointer;
    font-size: 30px;
    padding: 0 20px;
  }
  .burger-menu .nav-home img {
    width: 25%;
    min-width: 150px;
  }
  .show-menu {
    position: fixed;
    top: 0;
    right: 0;
    padding-top: 120px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100vw;
    min-width: 100%;
    height: 100vh;
    min-height: calc(100vh + var(--header-h));
    background-image: url("../../images/front_bg_small.jpg");
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .show-menu .nav-link {
    margin: 10px 0;
    z-index: 999;
    position: relative;
    font-size: clamp(16px, 0.9rem, 18px);
  }
  .show-menu .nav-home {
    margin-bottom: 70px;
    width: 100%;
    text-align: center;
  }
  .show-menu .nav-home svg {
    font-size: clamp(30px, 10vw, 50px) !important;
    color: var(--mf) !important;
  }
  .show-menu .nav-home:hover svg {
    color: var(--gold) !important;
    transition: color 0.3s;
  }
}
