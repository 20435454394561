#vacancies-wrapper main {
  position: relative;
  top: calc(var(--header-h) * -1);
}

#vacancies-wrapper section {
  width: 100%;
}

#vacancies-wrapper .vacancies-intro-wrapper {
  width: 100%;
  height: calc(100vw / 2.9);
  max-height: 65vh;
  position: relative;
  overflow: hidden;
}

#vacancies-wrapper .vacancies-intro-wrapper img {
  filter: brightness(70%);
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: fill;
  animation: verticalMoveImg 45s infinite linear;
}

@keyframes verticalMoveImg {
  0% {
    translate: 0;
  }
  100% {
    translate: 0 -25%;
  }
}

#vacancies-wrapper .vacancies-intro-wrapper .content-wrapper {
  width: 100%;
  height: 100%;
  padding: 7rem;
  position: absolute;
}

#vacancies-wrapper .vacancies-intro-wrapper .content-wrapper h1 {
  position: absolute;
  width: calc(100% - 14rem);
  display: block;
  bottom: calc(100vw / 15);
  color: ghostwhite;
  font-family: "Geologica", sans-serif;
  font-size: 6rem;
  font-weight: 600;
  text-transform: capitalize;
}

#vacancies-wrapper .vacancies-intro-wrapper .content-wrapper h1::after {
  content: "";
  position: absolute;
  bottom: calc(100vw / -45);
  left: 0;
  width: 100%;
  height: 0.1rem;
  background-color: ghostwhite;
  opacity: 0.6;
}

#vacancies-wrapper .choose-language-wrapper {
  width: 70%;
  margin: 6rem auto;
  margin-bottom: 3rem;
}
#vacancies-wrapper .choose-language-wrapper button {
  padding: 0.5rem 2rem;
  margin-right: 0.5rem;
  cursor: pointer;
  font-size: clamp(14px, 1.2rem, 18px);
  font-weight: 500;
  border: 1px solid var(--gold);
  background-color: white;
}
#vacancies-wrapper .choose-language-wrapper button:hover {
  box-shadow: rgba(25, 44, 73, 0.2) 0px 4px 10px;
  border-color: rgb(17, 33, 46);
}
#vacancies-wrapper .job-offers-wrapper {
  width: 70%;
  margin: 0 auto;
  position: relative;
}
#vacancies-wrapper .job-offers-wrapper h2 {
  font-size: clamp(32px, 3.5rem, 50px);
  line-height: 4rem;
  font-weight: 700;
  letter-spacing: -2px;
  font-family: "Manrope", sans-serif;
  margin-bottom: 3rem;
}

#vacancies-wrapper .job-offers-wrapper .offers-item {
  padding: 2rem;
  border-bottom: 1px solid rgb(17, 33, 46);
  transition: all 0.25s;
}

#vacancies-wrapper .job-offers-wrapper .offers-item:hover {
  background-color: rgb(17, 33, 46);
  color: ghostwhite;
  transition: all 0.9s;
}
#vacancies-wrapper .job-offers-wrapper .offers-item:hover button {
  color: ghostwhite;
  box-shadow: inset 0 0 0 1px ghostwhite;
  transition: all 0.25s;
}
#vacancies-wrapper .job-offers-wrapper h3 {
  font-size: clamp(24px, 2rem, 28px);
  font-weight: 700;
  font-family: "Manrope", sans-serif;
  margin-bottom: clamp(30px, 1rem, 50px);
}
#vacancies-wrapper .job-offers-wrapper button {
  background: none;
  position: relative;
  border: none;
  padding: 0.4rem 1rem;
  cursor: pointer;
  font-family: "Manrope", sans-serif;
  font-size: clamp(12px, 1rem, 16px);
  font-weight: 500;
  box-shadow: inset 0 0 0 1px rgb(7, 7, 68);
}

#vacancies-wrapper .job-offers-wrapper .offers-item button:before,
#vacancies-wrapper .job-offers-wrapper .offers-item button:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 1px solid transparent;
  box-sizing: inherit;
}

#vacancies-wrapper .job-offers-wrapper .offers-item button:before {
  top: 0;
  left: 0;
}

#vacancies-wrapper .job-offers-wrapper .offers-item button:after {
  bottom: 0;
  right: 0;
}

#vacancies-wrapper .job-offers-wrapper .offers-item button:hover::before {
  border-top-color: var(--gold);
  border-right-color: var(--gold);
  width: 100%;
  height: 100%;
  transition: width 0.25s linear, height 0.25s linear 0.25s;
}

#vacancies-wrapper .job-offers-wrapper .offers-item button:hover::after {
  border-bottom-color: var(--gold);
  border-left-color: var(--gold);
  width: 100%;
  height: 100%;
  transition: border-color 0s linear 0.5s, width 0.25s linear 0.5s,
    height 0.25s linear 0.75s;
}

@media (max-width: 1175px) {
  #vacancies-wrapper .choose-language-wrapper,
  #vacancies-wrapper .job-offers-wrapper {
    width: 90%;
  }
  #vacancies-wrapper .job-offers-wrapper {
    margin-top: 60px;
  }
  #vacancies-wrapper .choose-language-wrapper button {
    padding: 8px 20px;
  }
  #vacancies-wrapper .job-offers-wrapper .offers-item {
    padding: 3rem 2rem;
  }
  #vacancies-wrapper .job-offers-wrapper button {
    padding: 7px 15px;
  }
}

@media (max-width: 920px) {
  #vacancies-wrapper header a.nav-link,
  #vacancies-wrapper header .burger-btn svg[data-icon="xmark"] {
    color: var(--mf);
  }
  #vacancies-wrapper .vacancies-intro-wrapper {
    height: calc(100vw / 2);
  }
  #vacancies-wrapper .vacancies-intro-wrapper .content-wrapper h1 {
    bottom: calc(100vw / 8);
  }
}
