#job-wrapper section {
  width: 100%;
  position: relative;
}
#job-wrapper main {
  position: relative;
  top: calc(var(--header-h) * -1);
}
#job-wrapper .job-intro-wrapper {
  width: 100%;
  height: calc(100vw / 2.9);
  max-height: 65vh;
  position: relative;
  overflow: hidden;
}

#job-wrapper .job-intro-wrapper img {
  filter: brightness(70%);
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: fill;
  animation: verticalMoveImg 45s infinite linear;
}

@keyframes verticalMoveImg {
  0% {
    translate: 0;
  }
  100% {
    translate: 0 -25%;
  }
}

#job-wrapper .job-intro-wrapper .content-wrapper {
  width: 100%;
  height: 100%;
  padding: 7rem;
  position: absolute;
}

#job-wrapper .job-intro-wrapper .content-wrapper h1 {
  position: absolute;
  width: calc(100% - 14rem);
  display: block;
  bottom: calc(100vw / 15);
  color: ghostwhite;
  font-family: "Geologica", sans-serif;
  font-size: clamp(35px, 4.5rem, 70px);
  font-weight: 600;
}

#job-wrapper .job-intro-wrapper .content-wrapper h1::after {
  content: "";
  position: absolute;
  bottom: calc(100vw / -45);
  left: 0;
  width: 100%;
  height: 0.1rem;
  background-color: ghostwhite;
  opacity: 0.6;
}

#job-wrapper .job-description-wrapper {
  width: 70%;
  margin: 7rem auto;
}
#job-wrapper .job-description-wrapper h2 {
  font-size: clamp(20px, 3rem, 30px);
  font-weight: 700;
  letter-spacing: -1px;
  word-spacing: 3px;
  font-family: "Manrope", sans-serif;
  margin-bottom: clamp(20px, 1.2rem, 40px);
}
#job-wrapper .job-description-wrapper p {
  font-size: clamp(16px, 1.2rem, 20px);
  margin-bottom: clamp(20px, 1.2rem, 40px);
}
#job-wrapper .job-description-wrapper h4 {
  font-size: clamp(16px, 1.3rem, 20px);
  font-weight: 700;
  margin-bottom: clamp(5px, 1rem, 20px);
  margin-top: clamp(22px, 1.3rem, 36px);
  text-transform: capitalize;
}
#job-wrapper .job-description-wrapper ul {
  list-style-type: square;
  margin-left: clamp(20px, 1.5rem, 25px);
  font-size: clamp(15px, 1.2rem, 18px);
}
#job-wrapper .job-description-wrapper p.endnote {
  margin-top: 2rem;
}
#job-wrapper .job-form-wrapper {
  width: 70%;
  margin: 3rem auto;
}
#job-wrapper .job-form-wrapper h2 {
  font-size: clamp(24px, 3rem, 36px);
  font-weight: 700;
  letter-spacing: -1px;
  word-spacing: 3px;
  font-family: "Manrope", sans-serif;
  margin-bottom: clamp(30px, 1.5rem, 50px);
  text-transform: capitalize;
}
#application-form {
  width: 100%;
  max-width: 600px;
}

#application-form .form-input {
  width: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid rgb(7, 7, 68);
  color: var(--mf);
  font-size: clamp(20px, 1.3rem, 24px);
  font-weight: 100;
  outline: none;
  margin-bottom: 50px;
  padding: 10px 0;
  overflow: hidden;
  background-clip: text;
  -webkit-background-clip: text;
  padding-left: 2px;
}

#application-form .form-input:focus {
  color: var(--gold);
  border-color: var(--gold);
  caret-color: var(--gold);
}

#application-form .form-input::placeholder {
  text-transform: uppercase;
  font-family: "Playfair Display", serif;
  padding-left: 1px;
}

#application-form::placeholder:focus {
  color: var(--gold);
}

#application-form textarea {
  border: 1px solid rgb(7, 7, 68) !important;
  font-family: "Playfair Display", serif;
  padding-left: 2px;
  max-height: 500px;
  max-width: 600px;
}

#application-form textarea:focus {
  color: var(--gold);
  border-color: var(--gold) !important;
}

#application-form .form-input[type="submit"] {
  position: relative;
  width: fit-content;
  height: 50px;
  padding: clamp(10px, 0.5rem, 15px) clamp(30px, 1rem, 40px);
  cursor: pointer;
  color: var(--mf);
  text-transform: uppercase;
  background-clip: unset !important;
  border: none;
  box-shadow: inset 0 0 0 1px rgb(7, 7, 68);
}

#application-form .form-input[type="submit"]:before,
#application-form .form-input[type="submit"]:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 1px solid transparent;
  box-sizing: border-box;
}

#application-form .form-input[type="submit"]:before {
  top: 0;
  left: 0;
}

#application-form .form-input[type="submit"]:after {
  bottom: 0;
  right: 0;
}

#application-form .form-input[type="submit"]:hover::before {
  border-top-color: var(--gold);
  border-right-color: var(--gold);
  width: 100%;
  height: 100%;
  transition: width 0.25s linear, height 0.25s linear 0.25s;
}

#application-form .form-input[type="submit"]:hover::after {
  border-bottom-color: var(--gold);
  border-left-color: var(--gold);
  width: 100%;
  height: 100%;
  transition: border-color 0s linear 0.5s, width 0.25s linear 0.5s,
    height 0.25s linear 0.75s;
}

@media (max-width: 1175px) {
  #job-wrapper .job-description-wrapper {
    width: 90%;
  }
  #job-wrapper .job-form-wrapper {
    width: 90%;
  }
}
@media (max-width: 920px) {
  #job-wrapper header a.nav-link,
  #job-wrapper header .burger-btn svg[data-icon="xmark"] {
    color: var(--mf);
  }
  #job-wrapper .job-intro-wrapper {
    height: calc(100vw / 2);
  }
  #job-wrapper .job-intro-wrapper .content-wrapper h1 {
    bottom: calc(100vw / 8);
  }
}

@media (max-width: 570px) {
  #job-wrapper .job-intro-wrapper .content-wrapper h1 {
    font-size: 24px;
  }
}
