#contact-wrapper section {
  width: 100%;
  position: relative;
}
#contact-wrapper #section-2,
#contact-wrapper #section-3 {
  padding: 0 20rem;
  margin: 8rem auto;
}
#contact-wrapper main {
  position: relative;
  top: calc(var(--header-h) * -1);
}

#contact-wrapper .contacts-intro-wrapper {
  width: 100%;
  height: calc(100vw / 2.9);
  max-height: 65vh;
  position: relative;
  overflow: hidden;
}

#contact-wrapper .contacts-intro-wrapper img {
  filter: brightness(70%);
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: fill;
  animation: verticalMoveImg 45s infinite linear;
}

@keyframes verticalMoveImg {
  0% {
    translate: 0;
  }
  100% {
    translate: 0 -25%;
  }
}

#contact-wrapper .contacts-intro-wrapper .content-wrapper {
  width: 100%;
  height: 100%;
  padding: 7rem;
  position: absolute;
}

#contact-wrapper .contacts-intro-wrapper .content-wrapper h1 {
  position: absolute;
  width: calc(100% - 14rem);
  display: block;
  bottom: calc(100vw / 15);
  color: ghostwhite;
  font-family: "Geologica", sans-serif;
  font-size: 6rem;
  font-weight: 600;
}

#contact-wrapper .contacts-intro-wrapper .content-wrapper h1::after {
  content: "";
  position: absolute;
  bottom: calc(100vw / -45);
  left: 0;
  width: 100%;
  height: 0.1rem;
  background-color: ghostwhite;
  opacity: 0.6;
}

.general-info-wrapper {
  position: relative;
  width: 100%;
  margin: 5rem auto;
}

.general-info-wrapper h2 {
  font-size: clamp(22px, 3.1rem, 45px);
  line-height: 4rem;
  font-weight: 700;
  letter-spacing: -2px;
  font-family: "Manrope", sans-serif;
  margin-bottom: 2.5rem;
}

.general-info-wrapper p {
  color: rgb(100, 99, 99);
  margin: 1rem 0;
  font-family: "Manrope", sans-serif;
  font-size: clamp(16px, 2rem, 20px);
}
.general-info-wrapper p span {
  color: var(--mf);
  font-weight: 600;
}
#contact-wrapper #section-3 {
  margin-top: clamp(90px, 8rem, 200px);
}
#section-3 .form-wrapper {
  width: 100%;
  margin: 0 auto;
}
#section-3 .form-wrapper h2 {
  font-size: clamp(22px, 3.1rem, 45px);
  line-height: 4rem;
  font-weight: 700;
  letter-spacing: -2px;
  font-family: "Manrope", sans-serif;
  margin-bottom: clamp(30px, 2.5rem, 50px);
}

#contact-form {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-width: 500px;
  max-width: 700px;
  justify-content: center;
  align-items: center;
  font-weight: 200;
}

#contact-form .form-input {
  width: 100%;
  background: none;
  border: none;
  border-bottom: 1px solid rgb(7, 7, 68);
  color: var(--mf);
  font-size: clamp(20px, 1.3rem, 24px);
  font-weight: 100;
  outline: none;
  margin-bottom: 50px;
  padding: 10px 0;
  overflow: hidden;
  background-clip: text;
  -webkit-background-clip: text;
  padding-left: 2px;
}

#contact-form .form-input:focus {
  color: var(--gold);
  border-color: var(--gold);
  caret-color: var(--gold);
}

#contact-form .form-input::placeholder {
  text-transform: uppercase;
  font-family: "Playfair Display", serif;
  padding-left: 1px;
}

#contact-form .form-input::placeholder:focus {
  color: var(--gold);
}

#contact-form textarea {
  border: 1px solid rgb(7, 7, 68) !important;
  font-family: "Playfair Display", serif;
  height: 10vw;
  min-height: 200px;
  padding-left: 2px;
}

#contact-form textarea:focus {
  color: var(--gold);
  border-color: var(--gold) !important;
}

#contact-form .form-input[type="submit"] {
  position: relative;
  min-width: 30%;
  width: fit-content;
  height: 50px;
  padding: clamp(10px, 0.5rem, 15px) clamp(30px, 1rem, 40px);
  cursor: pointer;
  color: var(--mf);
  text-transform: uppercase;
  background-clip: unset !important;
  border: none;
  box-shadow: inset 0 0 0 1px rgb(7, 7, 68);
}

#contact-form .form-input[type="submit"]:before,
#contact-form .form-input[type="submit"]:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border: 1px solid transparent;
  box-sizing: border-box;
}

#contact-form .form-input[type="submit"]:before {
  top: 0;
  left: 0;
}

#contact-form .form-input[type="submit"]:after {
  bottom: 0;
  right: 0;
}

#contact-form .form-input[type="submit"]:hover::before {
  border-top-color: var(--gold);
  border-right-color: var(--gold);
  width: 100%;
  height: 100%;
  transition: width 0.25s linear, height 0.25s linear 0.25s;
}

#contact-form .form-input[type="submit"]:hover::after {
  border-bottom-color: var(--gold);
  border-left-color: var(--gold);
  width: 100%;
  height: 100%;
  transition: border-color 0s linear 0.5s, width 0.25s linear 0.5s,
    height 0.25s linear 0.75s;
}

#contact-form input[type="file"] {
  font-size: 0px;
}

/* ----------- SUBMIT RESPONSE PAGES STYLES ------------ */
#submit-wrapper header {
  background-color: rgb(14, 25, 31);
}
#submit-wrapper header a,
#submit-wrapper header svg {
  color: ghostwhite;
  fill: ghostwhite;
}
#submit-success,
#submit-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10rem 5rem;
  text-align: center;
}

#submit-success h2,
#submit-error h2 {
  color: var(--mf);
  text-transform: none;
  font-size: clamp(20px, 1.5rem, 35px);
  font-weight: 400;
  margin-bottom: 2rem;
}

#submit-success div svg {
  color: var(--gold);
  font-size: 3rem;
}

#submit-error {
  color: var(--red);
  font-size: 1.7rem;
}

#submit-error button,
#submit-success button {
  height: clamp(30px, 3rem, 45px);
  margin-top: 2rem;
  padding: 0 1.1rem;
  background: none;
  background-clip: unset !important;
  border-radius: 5px;
  border: 1px solid var(--mf);
  cursor: pointer;
  color: var(--mf);
  font-size: clamp(12px, 0.9rem, 16px);
  text-transform: uppercase;
  transition: all 0.4s;
}

#submit-error button:hover,
#submit-success button:hover {
  color: var(--gold);
  border-color: var(--gold);
}

/* ----------- SMALLER MEDIA STYLES ------------ */
@media (max-width: 1400px) {
  #contact-wrapper #section-2,
  #contact-wrapper #section-3 {
    padding: 0 10rem;
  }
}

@media (max-width: 920px) {
  #contact-wrapper header a.nav-link,
  #contact-wrapper header .burger-btn svg[data-icon="xmark"],
  #submit-wrapper header a.nav-link,
  #submit-wrapper header .burger-btn svg[data-icon="xmark"] {
    color: var(--mf);
  }
  #contact-wrapper .contacts-intro-wrapper {
    height: calc(100vw / 2);
  }
  #contact-wrapper .contacts-intro-wrapper .content-wrapper h1 {
    bottom: calc(100vw / 8);
  }
}

@media (max-width: 776px) {
  #contact-wrapper #section-2,
  #contact-wrapper #section-3 {
    padding: 0 30px;
  }
  #contact-form {
    min-width: 400px;
    margin-top: 50px;
  }
  #contact-form textarea {
    height: 200px;
  }
  .general-info-wrapper h2,
  #section-3 .form-wrapper h2 {
    letter-spacing: -0.5px;
  }
}

@media (max-width: 570px) {
  #contact-wrapper .contacts-intro-wrapper .content-wrapper h1 {
    font-size: 35px;
  }
}
