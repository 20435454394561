#front-wrapper {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-image: url("../images/front_bg.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
#front-wrapper header a,
#front-wrapper header svg {
  color: var(--mf);
}

@keyframes appearance {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#about-wrapper,
#contact-wrapper,
#submit-wrapper,
#projects-wrapper,
#apply-form-wrapper {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
  min-width: var(--min-width);
  background-color: white;
}
