footer.front {
  position: absolute;
  bottom: 5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px;
  transition: 0.5s;
  z-index: 2;
}
footer.front #copyright p {
  font-size: clamp(10px, 0.8rem, 14px);
}

footer.large {
  width: 100%;
  min-height: 90vh;
  max-height: 1200px;
  background-image: url("../../images/footer_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 40%;
  padding: 5rem 15rem;
  color: ghostwhite;
}
.footer-top {
  position: relative;
  padding: 4rem 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.footer-top::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.01rem;
  background-color: rgba(248, 248, 255, 0.5);
}
.footer-top-logo {
  display: none;
  width: 40%;
  padding-top: 5rem;
}
.footer-top .item-group {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 1rem;
  padding: 1.5rem 3rem;
}

.footer-top .item-group + .item-group {
  margin-left: 3rem;
  position: relative;
}

.footer-top .item-group + .item-group::before {
  content: "";
  position: absolute;
  top: 0;
  left: -1rem;
  height: 100%;
  width: 1px;
  background-color: rgba(248, 248, 255, 0.5);
}

.footer-top .item-group > div {
  display: flex;
  flex-direction: column;
  gap: 0.3rem;
}
.footer-top .item-group svg {
  font-size: 2.5rem;
}

.footer-top .item-group span {
  color: rgba(248, 248, 255, 0.5);
  font-size: clamp(11px, 0.9rem, 16px);
  font-weight: 300;
  user-select: none;
  font-family: "Manrope", sans-serif;
}
.footer-top .item-group p {
  font-size: clamp(13px, 1.1rem, 18px);
  font-weight: 500;
}

.footer-top .item-group:hover svg,
.footer-top .item-group:hover p {
  color: var(--gold);
  transition: color 0.2s;
}
.footer-bottom {
  position: relative;
  width: 100%;
  padding: 10rem 4rem;
  display: flex;
  gap: 6rem;
}

.footer-bottom::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.01rem;
  background-color: rgba(248, 248, 255, 0.5);
}
.footer-bottom .bottom-left {
  flex: 1;
}
.footer-bottom .bottom-left img {
  width: 100%;
}
.footer-bottom .bottom-right {
  flex: 3;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.footer-bottom .bottom-right .item-group h3 {
  text-transform: uppercase;
  font-size: clamp(14px, 1.3rem, 20px);
  font-family: "Manrope", sans-serif;
  font-weight: 600;
  margin-bottom: 2rem;
  user-select: none;
}

.footer-bottom .bottom-right .item-group ul {
  list-style-type: none;
}
.footer-bottom .bottom-right .item-group ul li a,
.footer-bottom .bottom-right .item-group .footer-social a {
  color: ghostwhite;
  text-transform: uppercase;
  font-size: clamp(13px, 0.85rem, 16px);
  font-family: "Manrope", sans-serif;
  line-height: clamp(16px, 1rem, 20px);
}
.footer-bottom .bottom-right .item-group ul li a:hover {
  color: var(--gold);
  transition: color 0.2s;
}

.footer-bottom .bottom-right .item-group .footer-social {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.footer-bottom .bottom-right .item-group .footer-social svg {
  font-size: 2rem;
  margin-right: 0.5rem;
}
.footer-bottom .bottom-right .item-group .footer-social a:hover {
  color: var(--gold);
  transition: color 0.2s;
}

.footer-copyrights {
  width: 100%;
  position: relative;
  text-align: center;
}

.footer-copyrights p {
  width: 100%;
  position: absolute;
  bottom: -4.2rem;
  font-size: clamp(12px, 0.85rem, 16px);
  text-transform: capitalize;
  user-select: none;
}

@media (max-width: 1300px) {
  footer.large {
    min-height: 60vh;
    padding: 5rem;
  }
}

@media (max-width: 740px) {
  .footer-bottom,
  .footer-top {
    flex-direction: column;
  }
  .footer-top-logo {
    display: block;
    margin-bottom: 70px;
  }
  .footer-top-logo img {
    width: 100%;
    max-width: 400px;
  }
  .footer-top .item-group {
    gap: 2rem;
    justify-content: flex-start;
    text-align: left;
    width: 60%;
    margin-left: 15%;
  }

  .footer-top .item-group + .item-group {
    margin-left: 15%;
  }
  .footer-top .item-group + .item-group::before {
    display: none;
  }

  .footer-top .item-group svg {
    font-size: 25px;
  }
  .footer-bottom {
    padding-top: 6rem;
  }
  .footer-bottom .bottom-left {
    display: none;
  }
  .footer-bottom .bottom-right {
    width: 100%;
    align-items: center;
    flex-direction: column;
    gap: 50px;
  }
  .footer-bottom .bottom-right .item-group h3 {
    font-size: 25px;
    text-transform: capitalize;
    text-align: center;
    margin-bottom: 4rem;
  }
  .footer-bottom .bottom-right .item-group nav,
  .footer-bottom .bottom-right .item-group .footer-social {
    text-align: center;
  }
  .footer-bottom .bottom-right .item-group .footer-social {
    flex-direction: row;
    gap: 2rem;
    align-items: center;
  }
  .footer-bottom .bottom-right .item-group .footer-social svg {
    font-size: 25px;
    margin-right: 2rem;
  }
}
@media (max-width: 450px) {
  footer.front {
    justify-content: center;
  }
  .burger-menu svg {
    font-size: 25px;
  }
}
