:root {
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
  --gray: #c8c8c8;
  --mf: #1b140b;
  --gold: rgb(235, 139, 29);
  --header-h: 80px;
  --min-width: 386px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  box-sizing: border-box;
  width: 100%;
  min-width: var(--min-width);
  overflow-x: hidden;
  font-size: 1vw;
  font-family: "Rubik", sans-serif;
  scroll-behavior: smooth;
}

body {
  scroll-behavior: smooth;
  position: relative;
  background-color: white;
  color: var(--mf);
  margin: 0 auto;
  width: 100vw;
  max-width: 2500px;
  min-width: var(--min-width);
  min-height: 100vh;
  overflow-x: hidden;
  font-size: 1rem;
}

#root {
  position: relative;
  width: 100%;
  min-width: var(--min-width);
  min-height: 100vh;
  margin: 0 auto;
}

a {
  text-decoration: none;
  color: var(--mf);
}

::-webkit-scrollbar {
  display: none;
}

*::selection {
  color: yellowgreen;
  background-color: var(--mf);
}
