#apply-form-wrapper header {
  background-color: rgb(15, 24, 31);
}

#apply-form-wrapper main {
  background-image: url("../../images/bg_concrete_wall.jpg");
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}
#apply-form-wrapper main > div {
  padding: 5rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#apply-form-wrapper form {
  margin-top: 20px;
  padding: 30px 0;
}
#apply-form-wrapper form > div {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 30px;
}
#apply-form-wrapper form .input-group {
  width: 370px;
  margin: 10px auto;
}
#apply-form-wrapper form label {
  display: block;
  margin-bottom: 2px;
  font-size: 18px;
  font-weight: 500;
}
#apply-form-wrapper form fieldset {
  padding: 5px 10px;
}

#apply-form-wrapper form fieldset input {
  width: fit-content;
  margin-right: 5px;
}
#apply-form-wrapper form fieldset input:focus,
#apply-form-wrapper form fieldset input:active {
  outline: none;
}
#apply-form-wrapper form fieldset label {
  display: inline;
  margin-right: 15px;
  font-size: 15px;
  font-weight: normal;
}
#apply-form-wrapper form fieldset legend {
  font-weight: 500;
}
#apply-form-wrapper form input {
  width: 100%;
  padding: 8px 5px;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px;
}
#apply-form-wrapper form input:active,
#apply-form-wrapper form input:focus {
  outline: 1px solid red;
}
#apply-form-wrapper input[type="file"] {
  font-size: inherit;
}
#apply-form-wrapper button {
  margin-bottom: 20px;
  margin-top: 10px;
  background-color: red;
  color: white;
  width: 200px;
  border: none;
  padding: 10px 5px;
  border-radius: 5px;
  font-size: 14px;
  cursor: pointer;
  text-transform: uppercase;
}
#apply-form-wrapper button:hover {
  background-color: rgb(182, 5, 5);
  transition: all 0.4s;
}

#apply-form-wrapper p {
  margin: 5px 0;
}
#apply-form-wrapper .form-sent-wrapper {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
#apply-form-wrapper .form-sent-wrapper a {
  color: black;
  margin-top: 10px;
}
#apply-form-wrapper .error-message {
  color: red;
  margin-top: 10px;
}

@media (max-width: 920px) {
  #apply-form-wrapper form > div {
    flex-direction: column;
  }
}
