#projects-wrapper main {
  position: relative;
  top: calc(var(--header-h) * -1);
}

#projects-wrapper .projects-intro-wrapper {
  width: 100%;
  height: calc(100vw / 2.9);
  position: relative;
  overflow: hidden;
}

#projects-wrapper .projects-intro-wrapper img {
  filter: brightness(70%);
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: fill;
  animation: verticalMoveImg 45s infinite linear;
}

@keyframes verticalMoveImg {
  0% {
    translate: 0;
  }
  100% {
    translate: 0 -25%;
  }
}

#projects-wrapper .projects-intro-wrapper .content-wrapper {
  width: 100%;
  height: 100%;
  padding: 7rem;
  position: absolute;
}

#projects-wrapper .projects-intro-wrapper .content-wrapper h1 {
  position: absolute;
  width: calc(100% - 14rem);
  display: block;
  bottom: calc(100vw / 15);
  color: ghostwhite;
  font-family: "Geologica", sans-serif;
  font-size: 6rem;
  font-weight: 600;
}

#projects-wrapper .projects-intro-wrapper .content-wrapper h1::after {
  content: "";
  position: absolute;
  bottom: calc(100vw / -45);
  left: 0;
  width: 100%;
  height: 0.1rem;
  background-color: ghostwhite;
  opacity: 0.6;
}

#projects-wrapper #section-2 {
  position: relative;
  width: 90%;
  margin: 7rem auto;
}

#projects-wrapper .project-item {
  position: relative;
  width: 100%;
  height: 85vh;
  margin-bottom: 10rem;
  padding: 2rem 4rem;
  display: flex;
  align-items: center;
  background-color: rgb(17, 33, 46);
}

#projects-wrapper .project-item .text {
  position: relative;
  min-width: 400px;
  flex: 3;
  height: 100%;
  z-index: 99;
}

#projects-wrapper .project-item .text > div {
  position: absolute;
  top: 15%;
  width: 100%;
  padding: 4rem 3rem;
  color: ghostwhite;
}

#projects-wrapper .project-item .text h3 {
  font-size: clamp(30px, 2.3rem, 40px);
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Manrope", sans-serif;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--gold);
}

#projects-wrapper .project-item .text p {
  font-size: clamp(18px, 1.2rem, 22px);
  line-height: clamp(22px, 2rem, 24px);
  font-weight: 300;
  margin-top: 4rem;
}

#projects-wrapper .project-item .gallery {
  height: 90% !important;
  flex: 5;
  z-index: 1;
}

#projects-wrapper .project-item .gallery .image-gallery,
#projects-wrapper .project-item .gallery .image-gallery-content,
#projects-wrapper .project-item .gallery .image-gallery-slide-wrapper,
#projects-wrapper .project-item .gallery .image-gallery-slides,
#projects-wrapper .project-item .gallery .image-gallery-swipe,
#projects-wrapper
  .project-item
  .gallery
  .image-gallery-slide.image-gallery-center {
  height: 100% !important;
  max-height: 100% !important;
}

#projects-wrapper .image-gallery-left-nav,
#projects-wrapper .image-gallery-right-nav {
  padding: 50px 80px;
}
#projects-wrapper .image-gallery-fullscreen-button {
  padding: 0px 10%;
}
#projects-wrapper .image-gallery-fullscreen-button:hover svg,
#projects-wrapper .image-gallery-left-nav:hover svg,
#projects-wrapper .image-gallery-right-nav:hover svg {
  stroke: var(--gold);
}
#projects-wrapper .project-item .gallery img {
  width: 100%;
  max-height: 100% !important;
  aspect-ratio: 1;
  object-fit: contain !important;
}

@media (max-width: 1540px) {
  #projects-wrapper .project-item {
    height: auto;
    padding: 50px;
    align-items: flex-start;
  }
  #projects-wrapper .project-item .text > div {
    left: 0% !important;
    right: inherit !important;
  }
  #projects-wrapper .image-gallery-fullscreen-button {
    padding: 0;
  }
}
@media (max-width: 1200px) {
  #projects-wrapper .project-item .text {
    min-width: 300px;
  }
}
@media (max-width: 920px) {
  #projects-wrapper header a.nav-link,
  #projects-wrapper header .burger-btn svg[data-icon="xmark"] {
    color: var(--mf);
  }
  #projects-wrapper .projects-intro-wrapper {
    height: calc(100vw / 1.95);
  }
  #projects-wrapper .projects-intro-wrapper .content-wrapper h1 {
    bottom: calc(100vw / 7);
  }

  #projects-wrapper .project-item {
    flex-direction: column !important;
    align-items: center;
  }

  #projects-wrapper .project-item .text > div {
    position: relative;
  }
  #projects-wrapper .project-item .gallery img {
    object-fit: cover !important;
  }
  #projects-wrapper .image-gallery-left-nav,
  #projects-wrapper .image-gallery-right-nav {
    padding: 2%;
  }
  #projects-wrapper .image-gallery-fullscreen-button {
    position: relative;
    display: block;
    top: 0;
    left: 0;
  }
}

@media (max-width: 570px) {
  #projects-wrapper .projects-intro-wrapper .content-wrapper h1 {
    font-size: 35px;
  }
  #projects-wrapper .project-item {
    padding: 10px;
  }
}
