#cloud-effect {
  position: absolute;
  width: 100%;
  height: calc(100vh + 50px);
  top: -50px;
  background: url("../../images/clouds.png");
  animation: clouds 50s linear infinite;
}

@keyframes clouds {
  0% {
    background-position: 600px;
  }
  60% {
    opacity: 0.5;
  }
  80% {
    opacity: 0.4;
  }
  90% {
    opacity: 0.8;
  }
  100% {
    background-position: 8000px;
    opacity: 1;
  }
}
