#about-wrapper main {
  position: relative;
  top: calc(var(--header-h) * -1);
}

#about-wrapper section {
  width: 100%;
}

#about-wrapper .about-intro-wrapper {
  width: 100%;
  height: calc(100vw / 2.9);
  max-height: 65vh;
  position: relative;
  overflow: hidden;
}

#about-wrapper .about-intro-wrapper img {
  filter: brightness(70%);
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: fill;
  animation: verticalMoveImg 45s infinite linear;
}

@keyframes verticalMoveImg {
  0% {
    translate: 0;
  }
  100% {
    translate: 0 -25%;
  }
}

#about-wrapper .about-intro-wrapper .content-wrapper {
  width: 100%;
  height: 100%;
  padding: 7rem;
  position: absolute;
}

#about-wrapper .about-intro-wrapper .content-wrapper h1 {
  position: absolute;
  width: calc(100% - 14rem);
  display: block;
  bottom: calc(100vw / 15);
  color: ghostwhite;
  font-family: "Geologica", sans-serif;
  font-size: 6rem;
  font-weight: 600;
}

#about-wrapper .about-intro-wrapper .content-wrapper h1::after {
  content: "";
  position: absolute;
  bottom: calc(100vw / -45);
  left: 0;
  width: 100%;
  height: 0.1rem;
  background-color: ghostwhite;
  opacity: 0.6;
}

#about-wrapper #section-2 {
  width: 100%;
  padding: 12rem 10rem;
}

.whoweare-wrapper {
  width: 85%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  gap: 1rem;
}

.whoweare-wrapper > div {
  flex: 1;
}

.whoweare-wrapper .text {
  position: relative;
}

.whoweare-wrapper .text h2 {
  font-size: clamp(20px, 3.2rem, 50px);
  line-height: 4rem;
  font-weight: 700;
  letter-spacing: -2px;
  font-family: "Manrope", sans-serif;
  margin-bottom: 3rem;
}

.whoweare-wrapper .text h2::before {
  content: "Who    we     are";
  color: rgb(85, 83, 83);
  text-transform: uppercase;
  font-size: clamp(12px, 1.2rem, 18px);
  word-spacing: 1px;
  letter-spacing: normal;
  position: absolute;
  top: -4.5rem;
  left: -1.5rem;
}

.whoweare-wrapper .text p {
  font-size: clamp(12px, 1.4rem, 20px);
  line-height: 2rem;
  color: rgb(85, 83, 83);
}

.whoweare-wrapper .media img {
  width: 100%;
  position: relative;
  top: 3rem;
  left: 5rem;
  border-radius: 2px;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  transition: transform 0.5s ease-in-out;
}

#about-wrapper #section-3 {
  position: relative;
  background-image: url("../../images/whoweare_bg.png");
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  padding: 12rem 10rem;
}

.values-wrapper {
  width: 70%;
  margin: 0 auto;
}

.values-wrapper .text {
  position: relative;
}

.values-wrapper .text h2 {
  position: relative;
  font-size: clamp(20px, 2.8rem, 45px);
  line-height: 3.2rem;
  font-weight: 500;
  letter-spacing: -2px;
  font-family: "Manrope", sans-serif;
  margin-bottom: 6rem;
}

.values-wrapper .text h2::before {
  content: "Our values";
  color: rgb(85, 83, 83);
  text-transform: uppercase;
  font-size: clamp(12px, 1.2rem, 18px);
  word-spacing: 1px;
  letter-spacing: normal;
  position: absolute;
  top: -4.5rem;
  left: -1.5rem;
}

.values-wrapper .text h2 span {
  font-family: sans-serif;
}

.values-wrapper .media {
  width: 100%;
  margin: 0 auto;
  display: flex;
  gap: 0.5rem;
}

.values-wrapper .media .item-group {
  flex: 1;
  width: calc(100% / 4.5);
  user-select: none;
  text-align: center;
}

.values-wrapper .media .item-group p {
  margin: 1rem;
  font-family: "Manrope", sans-serif;
  font-size: clamp(13px, 1.3rem, 20px);
  font-weight: 600;
  letter-spacing: -0.04rem;
  color: rgb(85, 83, 83);
}
.values-wrapper .media .item-group p span {
  display: block;
  font-weight: 900;
  font-size: clamp(14px, 1.4rem, 22px);
  color: var(--mf);
  text-transform: uppercase;
  margin-bottom: 1rem;
}
.values-wrapper .media .item-group svg {
  color: var(--gold);
  font-size: 5rem;
}

#about-wrapper #section-4 {
  position: relative;
  width: 100%;
  margin: 0 auto;
  min-height: 80vh;
  padding: 15rem 5rem;
}

.weoffer-wrapper {
  width: 100%;
}

.weoffer-wrapper .text {
  width: 80%;
  position: relative;
  margin: 0 auto;
  margin-bottom: 5rem;
}

.weoffer-wrapper .text .offer-title {
  position: relative;
  font-size: 3.5rem;
  line-height: 4.4rem;
  font-weight: 700;
  letter-spacing: -2px;
  font-family: "Manrope", sans-serif;
  margin-bottom: 3rem;
  margin-right: 2rem;
  text-transform: uppercase;
}

.weoffer-wrapper .text::before {
  content: "What we offer";
  color: rgb(85, 83, 83);
  text-transform: uppercase;
  font-size: clamp(12px, 1.2rem, 18px);
  word-spacing: 1px;
  letter-spacing: normal;
  position: absolute;
  top: -4.5rem;
  left: -1.5rem;
}

.media .carousel.carousel-slider .control-arrow {
  display: none;
}
.media .carousel .control-dots {
  padding: 0.5rem;
  bottom: 3%;
}

.media .carousel .control-dots .dot {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  width: 12px;
  height: 12px;
  opacity: 1;
}

.media .carousel .control-dots .dot.selected {
  background-color: var(--gold);
}

.weoffer-wrapper .media .item-group {
  width: 85%;
  margin: 0 auto;
  padding: 0 0.5rem;
  height: 75vh;
  position: relative;
  left: -4%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.weoffer-wrapper .media .description {
  position: relative;
  flex: 3;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}

.weoffer-wrapper .media .item-group .description > div {
  position: absolute;
  left: 20%;
  width: 100%;
  height: 80%;
  padding: clamp(20px, 4rem, 50px);
  background-color: #013f41;
  color: ghostwhite;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: clamp(20px, 3rem, 30px);
  overflow: hidden;
}

.weoffer-wrapper .media .description h3 {
  font-size: clamp(25px, 2.2vw, 40px);
  font-weight: 400;
  text-transform: uppercase;
  font-family: "Manrope", sans-serif;
  width: 100%;
  padding-bottom: 20px;
  border-bottom: 1px solid ghostwhite;
  word-wrap: break-word;
}

.weoffer-wrapper .media .description p {
  font-size: clamp(16px, 1.7vw, 20px);
  line-height: clamp(18px, 2vw, 26px);
  font-weight: 300;
  margin-top: clamp(18px, 4rem, 30px);
  word-wrap: break-word;
  text-align: justify;
}

.weoffer-wrapper .media .item-group .image-wrapper {
  height: 100%;
  flex: 5;
  z-index: 1;
}

.weoffer-wrapper .media .item-group img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media (max-width: 1670px) {
  .whoweare-wrapper {
    width: 95%;
  }
  .values-wrapper {
    width: 85%;
  }
  .weoffer-wrapper .media .item-group {
    width: 90%;
  }
}

@media (max-width: 1550px) {
  .weoffer-wrapper .media .item-group {
    width: 100%;
  }
  .weoffer-wrapper .text {
    margin-bottom: 2rem;
  }
}
@media (max-width: 1375px) {
  .weoffer-wrapper .media .item-group {
    height: 70vh;
  }
  .weoffer-wrapper .media .item-group .description > div {
    height: 100%;
    justify-content: center;
  }
}
@media (max-width: 1275px) {
  .weoffer-wrapper .media .item-group {
    height: 60vh;
  }
}
@media (max-width: 1175px) {
  .weoffer-wrapper .media .item-group {
    height: 55vh;
  }
}
@media (max-width: 1000px) {
  .weoffer-wrapper {
    width: 80%;
    margin: auto;
  }
  .weoffer-wrapper .text {
    margin-top: 5rem;
  }
  .weoffer-wrapper .text::before {
    font-size: 16px;
  }
  .weoffer-wrapper .text .offer-title {
    font-size: 38px;
  }

  .weoffer-wrapper .media .item-group {
    height: 800px;
    flex-direction: column;
    margin: 30px auto;
    left: 0;
  }
  .weoffer-wrapper .media .item-group .description,
  .weoffer-wrapper .media .item-group .image-wrapper {
    flex: 1;
    width: 100%;
    height: 100%;
  }

  .weoffer-wrapper .media .item-group .description > div {
    position: relative;
    left: 0%;
    height: 100%;
    padding: 20px 60px;
  }

  .weoffer-wrapper .media .item-group .image-wrapper img {
    object-fit: cover;
    height: 525px;
  }
}

@media (max-width: 920px) {
  #about-wrapper header a.nav-link,
  #about-wrapper header .burger-btn svg[data-icon="xmark"] {
    color: var(--mf);
  }

  #about-wrapper .about-intro-wrapper {
    height: calc(100vw / 2);
  }

  #about-wrapper .about-intro-wrapper .content-wrapper h1 {
    bottom: calc(100vw / 8);
  }
  header div.front-contacts {
    display: none;
  }
  #about-wrapper #section-2,
  #about-wrapper #section-3 {
    padding: 5rem;
    padding-top: 20rem;
    margin: 5rem auto;
  }
  .whoweare-wrapper {
    flex-direction: column;
  }
  .whoweare-wrapper .media img {
    left: 0rem;
  }
  h2::before {
    font-size: 16px !important;
    top: -50px !important;
  }
  .whoweare-wrapper .text h2,
  .values-wrapper .text h2 {
    font-size: 35px;
    line-height: 43px;
    font-weight: 700;
  }
  .whoweare-wrapper .text p {
    font-size: 24px;
    line-height: 30px;
  }
  .values-wrapper .media {
    flex-wrap: wrap;
  }
  .values-wrapper .media .item-group {
    width: calc(100% / 2.5);
    flex: auto;
    margin: 30px auto;
  }
  .values-wrapper .media .item-group svg {
    font-size: 60px;
    margin-bottom: 20px;
  }
  .values-wrapper .media .item-group p {
    font-size: 18px;
  }
  .values-wrapper .media .item-group p span {
    font-size: 20px;
  }
}

@media (max-width: 570px) {
  #about-wrapper .about-intro-wrapper .content-wrapper h1 {
    font-size: 35px;
  }
  #about-wrapper #section-3 {
    margin-top: 100px;
    padding-bottom: 90px;
  }

  .whoweare-wrapper .text h2,
  .values-wrapper .text h2 {
    font-size: 30px;
    line-height: 40px;
  }
  .values-wrapper .text h2 {
    text-align: center;
  }
  h2::before {
    font-size: 14px !important;
    top: -40px !important;
  }
  .whoweare-wrapper .text p {
    font-size: 18px;
  }
  .values-wrapper .media .item-group {
    width: calc(100% / 1.7);
  }
  .values-wrapper .media .item-group svg {
    margin-bottom: 10px;
  }
  .values-wrapper .media .item-group p {
    width: 70%;
    margin: 0 auto;
  }
  .weoffer-wrapper {
    width: 90%;
  }
  .weoffer-wrapper .text {
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .weoffer-wrapper .text::before {
    top: -40px;
  }
  .weoffer-wrapper .text .offer-title {
    font-size: 30px;
  }
}
@media (max-width: 470px) {
  #about-wrapper #section-4 {
    min-height: 60vh;
  }
  .weoffer-wrapper {
    width: 100%;
  }
  .weoffer-wrapper .media .item-group {
    height: 600px;
  }
  .weoffer-wrapper .media .item-group .image-wrapper img {
    height: 400px;
  }
  .weoffer-wrapper .media .item-group .description > div {
    padding: 20px 40px;
  }
}
